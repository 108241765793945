
.canvas { position: absolute; width: 100vw; height: 100vh; z-index: -1; top: 0; left: 0; }

.canvas .hot-wrapper {position: absolute; display: flex; align-items: center; justify-content: center; opacity: 0; transform: scale(0.01); width: 0px; height: 0px; transition: transform 0.4s, opacity 0.4s;}
.canvas .hot-wrapper .hot-inner {position: relative; min-width: 150px; background-color: #E02F25; padding: 10px 20px; border-radius: 10px; color: white; font-size: 20px; display: flex; align-items: center; justify-content: center; transition: all 0.4s;}
.canvas .hot-wrapper .hot-arrow { width: 0;  height: 0;  border-left: 16px solid transparent; border-right: 16px solid transparent; border-top: 16px solid #E02F25; position: absolute; bottom: -16px;}

.canvas .hot-wrapper.active {opacity: 1; transform: scale(1);}
.canvas .hot-wrapper.active .hot-inner {margin-top: -100px;}

.canvas .play-wrapper { position: absolute; top: 150px; left: 100px; width: 60px; height: 60px; cursor: pointer; display: flex; justify-content: center; align-items: center; visibility:hidden;}
.canvas .play-wrapper img { width: 100%; transition: all 0.3s;}
.canvas .play-wrapper:hover img { transform: scale(1.2);}

.canvas .exter-back {position: absolute; width: 100vw; height: 100vh; top: 0; left: 0; z-index: -1; transition: all 0.5s; background-size:cover; background-position: center; background-image:'url(./backImage/exterior.jpg)'; background-repeat: no-repeat; background-size: 130% 130%;}
/* transform: scale(1.3); */
.canvas .exter-back img {position: absolute; cursor: pointer; width: 70px; height: 70px;}
.canvas .exter-back.zShow {z-index: 0;}
.canvas .exter-back.active {background-size: 100% 100%;}

#render2dWrapper { position: absolute; top: 0; left: 0;}
#render2d .canvasIcon { display: flex; justify-content: center; align-items: center; position: relative; }
#render2d .canvasIcon .hot-circle { border-radius: 50%; border: 5px solid; display: flex; justify-content: center; align-items: center; animation-name: hotPulse; animation-duration: 4s; animation-iteration-count: infinite; opacity: 0;}
#render2d .canvasIcon.delay-0 .hot-circle{border-color: rgba(255, 0, 0, 0.5); animation-delay: 0s;}
#render2d .canvasIcon.delay-1 .hot-circle{border-color: rgba(0, 102, 51, 0.5); animation-delay: 0.4s;}
#render2d .canvasIcon.delay-2 .hot-circle{border-color: rgba(51, 51, 0, 0.5); animation-delay: 0.8s;}
#render2d .canvasIcon.delay-3 .hot-circle{border-color: rgba(0, 102, 153, 0.5); animation-delay: 1.2s;}
#render2d .canvasIcon.delay-4 .hot-circle{border-color: rgba(153, 102, 51, 0.5); animation-delay: 1.6s;}

#render2d .canvasIcon .hot-image {position: absolute; top: 0; left: 0; width: 100%;}
#render2d .canvasIcon .hot-image img {width: 100%;}
/* #render2d .hot-label .label-string {font-size: 20px;} */

#render2d .canvasIcon .hot-back {position: absolute; top: 6%; left: 6%; width: 88%; height: 88%; border-radius: 50%; background-color: rgba(255, 255, 255, 0.3); transition: background-color 0.3s;}

#render2d .canvasIcon.delay-0:hover .hot-back{background-color: rgba(255, 0, 0, 0.5);}
#render2d .canvasIcon.delay-1:hover .hot-back{background-color: rgba(0, 102, 51, 0.5);}
#render2d .canvasIcon.delay-2:hover .hot-back{background-color: rgba(51, 51, 0, 0.5);}
#render2d .canvasIcon.delay-3:hover .hot-back{background-color: rgba(0, 102, 153, 0.5);}
#render2d .canvasIcon.delay-4:hover .hot-back{background-color: rgba(153, 102, 51, 0.5);}
/* #render2d .canvasIcon:hover .hot-back {opacity: 0.5;} */

@keyframes hotPulse {
	/* 0%   {transform: scale(1); opacity: 0;}
	10%  {transform: scale(1); opacity: 1;}
	50%  {transform: scale(2); opacity: 0;}
	100% {transform: scale(1); opacity: 0;} */
}

/* @media screen and (max-width: 1280px) {
    .canvas {height: calc(100vh - 55px);}
	.canvas .exter-back {height: calc(100vh - 55px);}
} */

.mobile .canvas {height: calc(100vh - 55px);}
.mobile .canvas .exter-back {height: calc(100vh - 55px);}