
.modal-back {position: absolute; top: 0; left: 0; width: 100vw; height: 100vh; z-index: -1; display: flex; align-items: center; justify-content: center;}
.modal-back.active {background-color: rgba(0, 0, 0, 0.2); z-index: 3!important;}
.modal-back .modal-wrapper {position: relative; padding: 15px; transition: all 0.5s; opacity: 0; box-shadow: 0px 3px 6px #00000029; background-color: white;}
.modal-back .modal-wrapper .modal-title { text-align: left; font-size: 20px; font-family: 'Roboto-regular'; margin-bottom: 10px;}
.modal-back .modal-wrapper.active { margin: 0px; opacity: 1; transform:scale(1);}
.modal-back .modal-wrapper .button {width: 120px; height: 40px;}


.multi-modal .modal-wrapper { flex-direction: column; transform: scale(0.1); } /* margin-top: 50vh; width: 800px;  */
.multi-modal-content { flex-direction: column; max-height: calc(100vh - 120px); overflow-y: auto; justify-content: flex-start;} /* margin-top: 40px; */
.multi-modal-content .scroll-wrapper {width: 100%;}
.multi-modal-content .multi-row { width: 100%; margin-top: 10px; } /* height: 260px; */
.multi-modal-content .multi-row .sub-item { flex: 1; height: 100%; margin: 0px 15px; flex-direction: column; cursor: pointer; transition: all 0.3s; box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%); max-width: calc(33vw - 30px);}
.multi-modal-content .multi-row .sub-item:hover { transform: scale(1.05); box-shadow: 6px 6px 2px 1px rgb(0 0 0 / 10%);}
.multi-modal-content .multi-row .sub-item .sub-image { height: calc(100% - 40px); width: 100%;}
.multi-modal-content .multi-row .sub-item .sub-image img { height: 100%; width: 100%;}
.multi-modal-content .multi-row .sub-item .sub-label {font-family: 'Roboto-medium'; font-size: 16px; color: white; background-color: black; width: 100%; padding: 8px 0px;}

.tv-stage-outer {width: 100%; height: 100%;}
.tv-stage-back {position: absolute; width: 100%; height: 100%; background-color: #e0e0e0;}
.tv-stage-back.multi-modal-content .multi-row {height: 250px;}
.tv-stage-back.multi-modal-content .multi-row .sub-item { width: 400px; flex: none;}
.tv-stage-back.multi-modal-content .multi-row .sub-item .sub-image { height: calc(100% - 60px);}
.tv-stage-back.multi-modal-content .multi-row .sub-item .sub-label {font-size: 20px; padding: 5px 0px;}
.tv-stage-back.stage-video-back {z-index: -1;}
.tv-stage-back.stage-video-back video {position: absolute; left: 25%; top: 25%; transform: scale(2); z-index: -1;}

.stage-video-back .back-button { position: absolute;  top:10px; left: 30px; color: red; font-size: 30px; font-family: 'Roboto-medium'; cursor: pointer; transition: top 0.3s;}
.stage-video-back .back-button img { height: 36px; margin-right: 12px;}
.stage-video-back .back-button label { cursor: pointer;}

.multi-modal.sub-opened .modal-wrapper { display: none; }

.sub-modal .modal-wrapper { flex-direction: column; width: 1400px; max-width: calc(100vw - 120px); max-height: calc(100% - 60px); min-height: 380px; transform:scale(0.1)}
.sub-modal.multi-sub-modal .modal-wrapper {opacity: 1; transform:scale(1);}
/* .sub-modal .content { margin: 0px; height: calc(100% - 170px); flex-direction: column;} */
.sub-modal .content .single-img{width: 100%; margin-bottom: 10px;}
/* .sub-modal .main-image, .sub-modal .video-content {max-height: calc(100vh - 310px); overflow: hidden;} */
.sub-modal .main-image img, .sub-modal .video-content video { height: auto; max-height: calc(100vh - 120px); }
.sub-modal .video-content video { width: 100%; }
.sub-modal .object-row { height: 60px;}
.sub-modal .object-row .button { width: 140px; height: 40px; margin: 10px;}
.sub-modal .object-row .button svg { transform: scale(0.8);}
.sub-modal .modal-wrapper .modal-title.multi-sub-title { text-align: center; }
.sub-modal .modal-wrapper .back-button { position: absolute;  top: 16px; left: 30px; color: red; font-size: 20px; font-family: 'Roboto-medium'; cursor: pointer;}
.sub-modal .modal-wrapper .back-button img { height: 18px; margin-right: 12px;}
.sub-modal .modal-wrapper .back-button label { cursor: pointer;}
.sub-modal .modal-wrapper .side-part { flex: 1; margin: 0px 10px;}
.sub-modal .modal-wrapper .side-part.left-side img { max-width: calc(50vw - 50px); max-height: calc(100vh - 120px);}
/* max-width: calc(50vw - 50px); */
.sub-modal .modal-wrapper .side-part.right-side { margin-left: 30px; text-align: left; font-size: 18px; line-height: 150%; max-height: calc(100vh - 180px); overflow-y: auto;}
/* .sub-modal .modal-wrapper .exterior-content {padding: 50px;} */
.sub-modal .modal-wrapper .exterior-content .modal-title {font-size: 24px; font-weight: 700; margin-bottom: 30px;}

.sub-modal .modal-wrapper .slider-content {width: calc(100% - 40px) ; margin: 0px 20px; overflow: hidden;}
.sub-modal .modal-wrapper .slider-content .slider-wrapper {height: 100%; transition: all 0.5s;}
.sub-modal .modal-wrapper .slider-content .slider-wrapper .slider-item {float: left;}
.sub-modal .modal-wrapper .slider-content .slider-wrapper .slider-item img {max-width: 100%; max-height: 100%;}
.sub-modal .modal-wrapper .slider-content .slider-arrow {position: absolute; top: 50%; width: 40px; height: 40px; border-radius: 50%; border: 1px solid; cursor: pointer;}
.sub-modal .modal-wrapper .slider-content .slider-arrow img {width: 60%; transition: all 0.2s; }
.sub-modal .modal-wrapper .slider-content .slider-arrow.arrow-back{left: 10px;}
.sub-modal .modal-wrapper .slider-content .slider-arrow.arrow-next{right: 10px;}
.sub-modal .modal-wrapper .slider-content .slider-arrow.arrow-back:hover img {left: 0; margin-right: 15px;}
.sub-modal .modal-wrapper .slider-content .slider-arrow.arrow-next:hover img {right: 0; margin-left: 15px;}
.sub-modal .modal-wrapper .slider-content .slider-arrow.disable {cursor: default; opacity: 0.3;}
.sub-modal .modal-wrapper .slider-content .slider-arrow.disable img {margin: 0!important;}

.sub-modal .modal-wrapper .label-content {margin-top: 100px; font-size: 48px; font-weight: 700;}

.tv-plane-source {display: none;}
.tvPlane .video-back {position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: -1; display: flex; align-items: center; justify-content: center; overflow: hidden;}
.tvPlane .video-back video {width: 120%; height: 120%;}
.tvPlane .button-area {width: 1400px; height: 787.5px; display: flex; justify-content: center;}
.tvPlane .button-area .tv-button {width: 160px; height: 140px; margin: 35px; position: relative; display: flex; align-items: center; justify-content: center; cursor: pointer;}
.tvPlane .button-area .tv-button {margin-top: 50px!important;}
.tvPlane .button-area .tv-button.mt-70 {margin-top: 60px!important;}
.tvPlane .button-area .tv-button.mt-100 {margin-top: 100px!important;}
.tvPlane .tv-hot-button {position: absolute; top: -80px; left: -80px; width: 160px; height: 160px; cursor: pointer;}
.tvPlane .tv-hot-button img {width: 100%; height: 100%;}

.tvPlane .tv-button .circle-inner {width: 100%; height: 100%; border-radius: 50%; display: flex; align-items: center; justify-content: center; color: white; line-height: 120%;}
.tvPlane .tv-button .circle-inner label {width: calc(100% - 30px);}
.tvPlane .tv-button .circle-outer {position: absolute; left: 0; top: 0; width: calc(100% - 20px); height: calc(100% - 20px); border-radius: 50%; border: 10px solid; animation-name: hotPulse; animation-duration: 4s; animation-iteration-count: infinite; opacity: 0;}

/* .tvPlane .tv-button:nth-child(1) .circle-outer {animation-delay: 0s;} */
.tvPlane .tv-button:nth-child(2) .circle-outer {animation-delay: 0.8s;}
.tvPlane .tv-button:nth-child(3) .circle-outer {animation-delay: 1.6s;}
.tvPlane .tv-button:nth-child(4) .circle-outer {animation-delay: 2.4s;}
.tvPlane .tv-button:nth-child(5) .circle-outer {animation-delay: 3.2s;}

#tvPlane_structure_main .tv-button .circle-inner {background: radial-gradient(closest-side, #E0DCAC, #E0DCAC, #E0DCAC, #FDDB13);}
#tvPlane_structure_main .tv-button .circle-outer {border-color:#FDDB13;}

#tvPlane_industry_main .tv-button .circle-inner {background: radial-gradient(closest-side, #EABCB9, #EABCB9, #EABCB9, #F9471B);}
#tvPlane_industry_main .tv-button .circle-outer {border-color:#F9471B;}

#tvPlane_life_main .tv-button {width: 120px; height: 120px; margin: 20px;}
#tvPlane_life_main .tv-button .circle-inner {background: radial-gradient(closest-side, #BAEAB4, #BAEAB4, #BAEAB4, #77F72A);}
#tvPlane_life_main .tv-button .circle-outer {border-color:#77F72A;}

#tvPlane_mobility_main .tv-button .circle-inner {background: radial-gradient(closest-side, #9CD5EF, #9CD5EF, #9CD5EF, #10C5FB);}
#tvPlane_mobility_main .tv-button .circle-outer {border-color:#10C5FB;}

/* .tv-modal .modal-wrapper { width: 1400px; max-width: 90vw;} height: 470px;  height: 100%;  */
.tv-modal * {font-family: 'futur-bold';}
.tvPlane.tv-modal-plane {position: relative;}
.tvPlane.tv-modal-plane .tv-modal-board {position: absolute; left: 0; top: 0; width: 100%; height: 100%; background-size: contain;}
.tvPlane.tv-modal-plane .tv-modal-board.tv-modal-type-board {background-image: url('../images/modal/kiosk-type-back.jpg'); top: 16.67%; height: 83.33%;}
.tvPlane.tv-modal-plane .tv-modal-board.tv-modal-type-board .type-wrapper {width: 95.7%; height: 97%; margin:1.25% 2.15%; border: 2px solid;}
.tvPlane.tv-modal-plane .tv-modal-type-board .type-wrapper .right-content {flex: 6; margin: 5px; width: calc(100% - 10px); height: calc(100% - 10px);}
.tvPlane.tv-modal-plane .tv-modal-type-board .type-wrapper .right-content img {max-width: 100%; max-height: 100%;}

.tvPlane.tv-modal-plane .tv-modal-type-board .type-wrapper .left-list {flex: 1; margin: 5px; overflow-y: auto; height: 100%;}
/* .tvPlane.tv-modal-plane .tv-modal-type-board .type-wrapper .left-list .list-wrapper {} */
.tvPlane.tv-modal-plane .tv-modal-type-board .type-wrapper .left-list img {margin: 3px 5px; border: 1px solid; width: calc(100% - 12px); cursor: pointer;}
.tvPlane.tv-modal-plane .tv-modal-type-board .type-wrapper .left-list img.active {border-color: red;}

.tvPlane.tv-modal-plane .video-back video {width: 100%; height: auto; }
.tvPlane.tv-modal-plane .video-back img {width: 100%; height: auto; }
.tvPlane.tv-modal-plane .tv-button {width: 110px; height: 110px; }
.tvPlane.tv-modal-plane .tv-button .circle-inner label {font-size: 16px; color: black;}

.tvPlane.tv-modal-plane .tv-modal-board .type-button {position: absolute; background-color: white; cursor: pointer; transition: all 0.3s; font-weight: 600;}
.tvPlane.tv-modal-plane .tv-modal-board .type-button:hover {background-color: #BBBBBB; color:#272d31}
.tvPlane.tv-modal-plane .tv-modal-board .type-close {position: absolute; background-color: black; cursor: pointer; transition: all 0.3s;}
.tvPlane.tv-modal-plane .tv-modal-board .type-close img {width: 60%; transition: all 0.3s;}
.tvPlane.tv-modal-plane .tv-modal-board .type-close:hover {background-color: #666666;}
.tvPlane.tv-modal-plane .tv-modal-board .type-close.hover-rotate:hover img {transform: rotate(-90deg);}

.info-modal .modal-wrapper { flex-direction: column; width: 560px; height: 480px; margin-top: -50vh; }
/* .info-modal .modal-wrapper.contact-wrapper {height: 360px;} */
.info-modal .content {margin-top: 0px; height: calc(100% - 60px);}
.info-modal .contact-wrapper .content  {overflow-y: auto;}
.info-modal .content .info-wrapper {margin-top: 0px;}
.info-modal .content .info-item {margin-top: 12px; display: flex;}
.info-modal .content .info-item.height-80 {height: 80px;}
.info-modal .content .info-item .info-image {width: 120px; display: flex; justify-content: center; align-items: center;}
.info-modal .content .info-item .info-image img {transform: scale(0.75); }
.info-modal .content .info-item .info-image img.icon {width: 55px; height: 55px;}
.info-modal .content .info-item .info-image img.arrow {transform: rotate(-90deg);}
.info-modal .content .info-item .info-image img.hotspot {border: 1px solid; border-radius: 50%;}
.info-modal .content .info-item .info-image.top {align-items: flex-start; margin-top: 10px;}
.info-modal .content .info-item .info-label { flex: 1; color: #525047; font-family:'Roboto-medium'; font-size: 14px; display: flex; flex-direction: column; text-align: left; margin-left: 20px; justify-content: center;}
.info-modal .content .contact-open {text-decoration: underline; font-weight: 700; cursor: pointer;}
.info-modal .modal-wrapper .back-button {position: absolute; top: 16px; left: 30px; cursor: pointer; font-weight: 700;}
.info-modal .content.contact {display: flex; width: 100%;}
.info-modal .content.contact .contact-side {flex: 1; text-align: left; margin: 5px;}
.info-modal .content.contact .contact-side .side-title {margin-bottom: 10px;}
.info-modal .content.contact .contact-side .side-content {height: calc(100% - 50px);}
.info-modal .content.contact .contact-side .side-content  .side-wrapper {max-height: 100%; overflow-y: auto; padding: 5px; border: 1px solid;}
.info-modal .content.contact .contact-side .sub-item {display: flex; width: 220px; margin-left: calc(100% - 230px);}
.info-modal .content.contact .contact-side .sub-item .sub-label {flex: 1;}
.info-modal .contact-footer {height: 40px; margin-top: 15px; width: 100%; }
.info-modal .contact-footer .submit {padding: 5px 20px; border-radius: 4px; border: 1px solid; cursor: pointer; font-weight: 700;}
.info-modal .contact-footer .links {margin-right: 40px; font-size: 12px;}
.info-modal .contact-footer .links label {cursor: pointer; color: blue;}


/* @media screen and (max-width: 1280px) {
	.modal-back {height: calc(100vh - 55px);}
	.modal-wrapper {max-width: 100vw; max-height: calc(100vh - 85px); height: calc(100vh - 85px); min-height: inherit;}
	.sub-modal .modal-wrapper .side-part.right-side {max-height: calc(100vh - 130px);}
	.multi-modal .modal-wrapper {width: calc(100vw - 30px) ; height: calc(100vh - 85px);}
	.tv-modal .modal-wrapper {width: 100%!important; height: calc(100% - 30px)!important;}
	.info-modal .modal-wrapper {height: calc(100vh - 85px);}
	.info-modal .content .info-item {margin-top: 0;}
} */

.mobile .modal-back {height: calc(100vh - 55px);}
.mobile .modal-wrapper {max-width: calc(100vw - 30px); max-height: calc(100vh - 85px); height: calc(100vh - 85px); min-height: inherit;}
.mobile .sub-modal .modal-wrapper .side-part.right-side {max-height: calc(100vh - 130px);}
.mobile .multi-modal .modal-wrapper {width: calc(100vw - 30px) ; height: calc(100vh - 85px);}
.mobile .tv-modal .modal-wrapper {width: 100%!important; height: calc(100% - 30px)!important;}
.mobile .info-modal .content .info-item {margin-top: 0;}
.mobile .info-modal .content {overflow-y: auto;}

.iOS .modal-wrapper {max-height: calc(100vh - 130px); height: calc(100vh - 130px);}
