
body {
	overflow: hidden;
}
.page-wrapper {
	width: 100vw;
	height: 100vh;
	background-repeat: no-repeat;
	background-position: center;
	overflow: hidden;
}
.back-board {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	opacity: 0;
	z-index: -1;
	background: no-repeat center center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.back-board.active { opacity: 1; z-index: 1; }
.flex { display: flex; align-items: center; justify-content: center;}
.flex-part {width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;}
.page-logo { position: absolute; width: 187px; top: 20px; left: 20px; z-index: 3; }

.button { color: #525047; padding: 15px; font-family: 'Roboto-regular'; font-size: 18px; cursor: pointer; transition: all 0.1s; display: flex; align-items: center; justify-content: center;}
.button.white-back { color: #91AA9D; background-color: white; border: 1px solid #91AA9D; }
.button svg path { transition: fill 0.3s; }
.button:hover svg path { fill: white; }

.close-icon { position: absolute; top: 10px; right: 10px; width: 32px; height: 32px; background-color: black; cursor: pointer; border-radius: 50%; }
.close-icon img { width: 16px; height: 16px; transition: transform 0.3s;}
.close-icon:hover img { transform: rotate(-90deg); }
.close-icon.hover-left:hover svg { animation: arrowAnimate 0.8s linear infinite; }

.loading { background-color: rgba(0, 0, 0, 0.5); opacity: 0; transition: all 0.5s;}
.loading.show { opacity: 1; z-index: 3;}
.loading .loading-circle {
	position: absolute;
	top: calc(50% - 60px);
	left: calc(50% - 60px);
	border: 16px solid #f3f3f3;
	border-top: 16px solid #3498db;
	border-radius: 50%;
	width: 90px;
	height: 90px;
	animation: spin 2s linear infinite;
}
.loading .loading-circle.grey {
	border: 6px solid #444;
	border-top: 6px solid white;
}

.loading .loading-label {font-size: 28px; color: white;}

@keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }
@keyframes arrowAnimate {
	0% { transform:translateX(30px); }
	100% { transform:translateX(-30px); }
}


.scroll { padding-right: 2px; }
.scroll::-webkit-scrollbar { width: 3px; height: 3px; }
.scroll::-webkit-scrollbar-track { background: #DCDEDF; }
.scroll::-webkit-scrollbar-thumb { background: #D37C5F; }
.scroll::-webkit-scrollbar-thumb:hover { background: #BBB; }

/* @media only screen and (max-width: 1280px) {
	.page-wrapper {height: calc(100vh - 55px);}
	.canvas {height: calc(100vh - 55px)!important;}
	.back-board {height: calc(100vh - 55px);}
} */

.mobile.page-wrapper {height: calc(100vh - 55px);}
.mobile .back-board {height: calc(100vh - 55px);}

.back-board.portrait {
	background-color: rgba(0, 0, 0, 0.8);
	opacity: 1;
	z-index: 3;
	color: white;
	font-size: 24px;
	font-weight: 700;
}
